import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";

const TextAreaField = ({
  input,
  meta,
  label,
  placeholder,
  required,
  className,
  maxLength,
  readOnly,
  rows,
  cols,
}) => (
  <div className={`form-group ${className}`}>
    {label && (
      <label
        htmlFor={input.name}
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        {label}
      </label>
    )}
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <textarea
      {...input}
      aria-label={placeholder}
      className="form-control"
      id={input.name}
      placeholder={placeholder}
      maxLength={maxLength}
      readOnly={readOnly}
      required={required}
      rows={rows}
      cols={cols}
    />
    {maxLength > 0 && (
      <div className="text-right pt-1 c-gray-600 label-small">
        {input.value.length}/{maxLength} characters
      </div>
    )}
  </div>
);
TextAreaField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  cols: PropTypes.number,
  readOnly: PropTypes.bool,
};

TextAreaField.defaultProps = {
  className: "",
  placeholder: "",
};

export default TextAreaField;
