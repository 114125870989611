import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Col, Row } from "react-bootstrap";
import {
  coveragePropType,
  getAdditionalInsuredInfo,
} from "../../Helpers/CoverageModel";
import {
  additionalInsuredPropTypes,
  TYPE_TEXT,
  statePropType,
  NO_AI_FACILITY,
  NO_AI_VENUE,
  NO_AI_VENUE_AI,
  NO_AI_OTHER,
  FACILITY_ADDRESS_GROUP_NAME,
  VENUE_ADDRESS_GROUP_NAME,
  VENUE_ADDRESS_AI_GROUP_NAME,
} from "../../constants";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { Button } from "@jauntin/react-ui";
import AdditionalInsuredField from "./FormElements/AdditionalInsuredField";
import CountryField from "./FormElements/CountryField";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import {
  descriptionOfOperationsMaxLength,
  descriptionOfOperationsMaxLengthValidator,
  zipCode,
} from "../../Helpers/validators";
import { normalizeZip, normalizeNoteMaxCharacters } from "../../normalizer";
import hasMeaningfulAddressInfo from "../../Helpers/hasMeaningfulAddressInfo";
import { validators } from "@jauntin/utilities";
import SelectListField from "@gbli-events/common/src/Components/FormElements/SelectListField/SelectListField";
import TextAreaField from "@gbli-events/common/src/Components/FormElements/TextAreaField";
import { normalizeDescriptionOfOperations } from "@gbli-events/common/src/Helpers/normalizers";

const { required } = validators;

const generateKey = (i) => {
  return `${i}`;
};

const parseAddress = (address) =>
  stringHelpers.address(
    address.address1,
    address.address2,
    address.city,
    address.state,
    address.country,
    address.zip
  );

const formatAdditionalInsuredText = (obj) => {
  if (TYPE_TEXT in obj) {
    return obj.text.split("\n").map((line, i) => {
      return <div key={generateKey(i)}>{line}</div>;
    });
  }
  return "";
};

const AdditionalInsured = ({
  coverage,
  editing,
  states,
  editFacility,
  editVenue,
  editVenueAdditionalInsured,
  setEditingFacility,
  setEditingVenue,
  setEditingVenueAdditionalInsured,
  resetFacilityAddressForm,
  resetVenueAddressForm,
  resetVenueAddressAdditionalInsuredForm,
  textCounter,
  facilityAddress,
  venueAddress,
  venueAddressAdditionalInsured,
  waiverOfTransferRightsIncluded,
  waiverOfTransferRightsAlwaysIncluded,
  clearAddressGroup,
}) => {
  const facilityAddressView = parseAddress(facilityAddress);
  const venueAddressView = parseAddress(venueAddress);
  const venueAddressAdditionalInsuredView = parseAddress(
    venueAddressAdditionalInsured
  );
  const additionalInsuredText = getAdditionalInsuredInfo(coverage, TYPE_TEXT);

  const stateOptions = states.map(({ name, code }) => ({
    label: name,
    value: code,
  }));

  const addressView = (address, view, noInfo, textDanger = false) => {
    if (hasMeaningfulAddressInfo(address)) {
      return (
        <>
          <div>{address.companyName}</div>
          <div>{view}</div>
        </>
      );
    }
    if (address.companyName) {
      return <div>{address.companyName}</div>;
    }
    return (
      <div className={textDanger ? "text-danger" : "additionalInsured--noInfo"}>
        {textDanger && <i className="fal fa-exclamation-triangle mr-2" />}
        {noInfo}
      </div>
    );
  };

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Additional Insured</strong>
      </div>
      <div className="card-body">
        {(editing && (
          <>
            {/* Facility */}
            <div className="row">
              <div className="col-3 h6">Organization</div>
              <div className="col-9">
                {editFacility ? (
                  <>
                    <Field
                      component={TextField}
                      name={`${FACILITY_ADDRESS_GROUP_NAME}.companyName`}
                      validate={[required]}
                      label="Organization Name"
                      ariaLabel="Organization Name"
                      placeholder="Organization Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <Field
                      component={TextField}
                      name={`${FACILITY_ADDRESS_GROUP_NAME}.address1`}
                      label="Street Address"
                      ariaLabel="Street Address"
                      placeholder="Address"
                      className="mb-2"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={TextField}
                      name={`${FACILITY_ADDRESS_GROUP_NAME}.address2`}
                      label="Address Line 2"
                      subtext="Apartment, suite, unit, building, floor, etc..."
                      ariaLabel="Address Line 2"
                      inputClassName="form-control-lg"
                    />
                    <div className="form-row">
                      <div className="col-sm">
                        <Field
                          component={TextField}
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.city`}
                          label="City"
                          ariaLabel="City"
                          placeholder="City"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.state`}
                          component={SelectListField}
                          options={stateOptions}
                          label="State"
                          placeholder="State"
                          searchable
                          resetValue={""}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          component={TextField}
                          validate={[zipCode]}
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.zip`}
                          normalize={normalizeZip}
                          label="Zip"
                          ariaLabel="Zip"
                          placeholder="Zip"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div hidden>
                        <Field
                          component={CountryField}
                          name={`${FACILITY_ADDRESS_GROUP_NAME}.country`}
                          type="select"
                          input={{ disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <Button
                        onClick={() => {
                          setEditingFacility(false);
                          resetFacilityAddressForm();
                        }}
                        text="Discard"
                        className="btn btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {addressView(
                      facilityAddress,
                      facilityAddressView,
                      NO_AI_FACILITY
                    )}
                    <Row>
                      {(hasMeaningfulAddressInfo(facilityAddress) ||
                        facilityAddress.companyName) && (
                        <Col sm="auto">
                          <Button
                            onClick={() =>
                              clearAddressGroup(FACILITY_ADDRESS_GROUP_NAME)
                            }
                            text="Clear"
                            className="btn btn-link btn--edit"
                          />
                        </Col>
                      )}
                      <Col sm="auto">
                        <Button
                          text="Edit"
                          onClick={() => setEditingFacility(true)}
                          className="btn btn-link btn-link--black btn--edit"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <br />

            {/* Venue */}
            <div className="row">
              <div className="col-3 h6">Venue</div>
              <div className="col-9">
                {editVenue ? (
                  <>
                    <Field
                      component={TextField}
                      name={`${VENUE_ADDRESS_GROUP_NAME}.companyName`}
                      label="Venue Name"
                      ariaLabel="Venue Name"
                      placeholder="Venue Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <Field
                      component={TextField}
                      validate={[required]}
                      name={`${VENUE_ADDRESS_GROUP_NAME}.address1`}
                      label="Street Address"
                      ariaLabel="Street Address"
                      placeholder="Address"
                      className="mb-2"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={TextField}
                      name={`${VENUE_ADDRESS_GROUP_NAME}.address2`}
                      label="Address Line 2"
                      subtext="Apartment, suite, unit, building, floor, etc..."
                      ariaLabel="Address Line 2"
                      inputClassName="form-control-lg"
                    />
                    <div className="form-row">
                      <div className="col-sm">
                        <Field
                          component={TextField}
                          validate={[required]}
                          name={`${VENUE_ADDRESS_GROUP_NAME}.city`}
                          label="City"
                          ariaLabel="City"
                          placeholder="City"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          name={`${VENUE_ADDRESS_GROUP_NAME}.state`}
                          component={SelectListField}
                          validate={[required]}
                          options={stateOptions}
                          label="State"
                          placeholder="State"
                          searchable
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          component={TextField}
                          validate={[zipCode, required]}
                          name={`${VENUE_ADDRESS_GROUP_NAME}.zip`}
                          normalize={normalizeZip}
                          label="Zip"
                          ariaLabel="Zip"
                          placeholder="Zip"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div hidden>
                        <Field
                          component={CountryField}
                          name={`${VENUE_ADDRESS_GROUP_NAME}.country`}
                          type="select"
                          input={{ disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row ml-left">
                      <Button
                        onClick={() => {
                          setEditingVenue(false);
                          resetVenueAddressForm();
                        }}
                        text="Discard"
                        className="btn btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {addressView(
                      venueAddress,
                      venueAddressView,
                      NO_AI_VENUE,
                      true
                    )}
                    <Row>
                      {(hasMeaningfulAddressInfo(venueAddress) ||
                        venueAddress.companyName) && (
                        <Col sm="auto">
                          <Button
                            onClick={() =>
                              clearAddressGroup(VENUE_ADDRESS_GROUP_NAME)
                            }
                            text="Clear"
                            className="btn btn-link btn--edit"
                          />
                        </Col>
                      )}
                      <Col sm="auto">
                        <Button
                          text="Edit"
                          onClick={() => setEditingVenue(true)}
                          className="btn btn-link btn-link--black btn--edit"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
            <br />

            {/* Venue Additional Insured */}
            <div className="row">
              <div className="col-3 h6">Venue Additional Insured</div>
              <div className="col-9">
                {editVenueAdditionalInsured ? (
                  <>
                    <Field
                      component={TextField}
                      name={`${VENUE_ADDRESS_AI_GROUP_NAME}.companyName`}
                      label="Venue Additional Insured Name"
                      ariaLabel="Venue Additional Insured Name"
                      placeholder="Venue Additional Insured Name"
                      inputClassName="form-control-lg mb-3"
                    />
                    <Field
                      component={TextField}
                      name={`${VENUE_ADDRESS_AI_GROUP_NAME}.address1`}
                      label="Street Address"
                      ariaLabel="Street Address"
                      placeholder="Address"
                      className="mb-2"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={TextField}
                      name={`${VENUE_ADDRESS_AI_GROUP_NAME}.address2`}
                      type="text"
                      label="Address Line 2"
                      subtext="Apartment, suite, unit, building, floor, etc..."
                      ariaLabel="Address Line 2"
                      inputClassName="form-control-lg"
                    />
                    <div className="form-row">
                      <div className="col-sm">
                        <Field
                          component={TextField}
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.city`}
                          label="City"
                          ariaLabel="City"
                          placeholder="City"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.state`}
                          component={SelectListField}
                          options={stateOptions}
                          label="State"
                          placeholder="State"
                          searchable
                          resetValue={""}
                        />
                      </div>
                      <div className="col-sm">
                        <Field
                          component={TextField}
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.zip`}
                          validate={[zipCode]}
                          type="text"
                          normalize={normalizeZip}
                          label="Zip"
                          ariaLabel="Zip"
                          placeholder="Zip"
                          inputClassName="form-control-lg"
                        />
                      </div>
                      <div hidden>
                        <Field
                          component={CountryField}
                          name={`${VENUE_ADDRESS_AI_GROUP_NAME}.country`}
                          type="select"
                          input={{ disabled: true }}
                        />
                      </div>
                    </div>
                    <div className="form-row ml-auto">
                      <Button
                        onClick={() => {
                          setEditingVenueAdditionalInsured(false);
                          resetVenueAddressAdditionalInsuredForm();
                        }}
                        text="Discard"
                        className="btn btn-outline-secondary px-4 mx-1 ml-auto"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {addressView(
                      venueAddressAdditionalInsured,
                      venueAddressAdditionalInsuredView,
                      NO_AI_VENUE_AI
                    )}
                    <Row>
                      {(hasMeaningfulAddressInfo(
                        venueAddressAdditionalInsured
                      ) ||
                        venueAddressAdditionalInsured.companyName) && (
                        <Col sm="auto">
                          <Button
                            onClick={() =>
                              clearAddressGroup(VENUE_ADDRESS_AI_GROUP_NAME)
                            }
                            text="Clear"
                            className="btn btn-link btn--edit"
                          />
                        </Col>
                      )}
                      <Col sm="auto">
                        <Button
                          text="Edit"
                          onClick={() => setEditingVenueAdditionalInsured(true)}
                          className="btn btn-link btn-link--black btn--edit"
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>

            {/* Other Additional Insured */}
            <div className="row">
              <div className="col-12 h6">Other additional insured</div>
              <div className="col-12 mb-2">
                <Field
                  component={AdditionalInsuredField}
                  type="textarea"
                  name="additionalInsured"
                  normalize={normalizeNoteMaxCharacters}
                />
                <div className="text-right policy--charCount">
                  {textCounter.length}
                  /200 characters
                </div>
              </div>
            </div>

            <Row>
              <Col>
                <div className="mt-3 form-row">
                  <div className="col-sm form-group">
                    <Field
                      component={TextAreaField}
                      normalize={normalizeDescriptionOfOperations}
                      validate={[
                        required,
                        descriptionOfOperationsMaxLengthValidator,
                      ]}
                      maxLength={descriptionOfOperationsMaxLength}
                      name="descriptionOfOperations"
                      label="DESCRIPTION OF OPERATIONS / LOCATIONS / VEHICLES"
                      rows={4}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {/* Waiver of Transfer Rights */}
            <div className="row">
              <div className="col my-4">
                <Field
                  component={StyledCheckboxField}
                  name="waiverOfTransferRights"
                  label="Form 2404 (Waiver of Transfer of Rights of Recovery Against Others to Us) included?"
                  disabled={waiverOfTransferRightsAlwaysIncluded}
                />
              </div>
            </div>
          </>
        )) || (
          <>
            <div>
              <strong>Organization</strong>
            </div>
            <div>
              {addressView(
                facilityAddress,
                facilityAddressView,
                NO_AI_FACILITY
              )}
            </div>
            <br />

            <div>
              <strong>Venue</strong>
            </div>
            <div>{venueAddress.companyName}</div>
            <div>{venueAddressView}</div>
            <br />

            <div>
              <strong>Venue Additional Insured</strong>
            </div>
            <div>
              {addressView(
                venueAddressAdditionalInsured,
                venueAddressAdditionalInsuredView,
                NO_AI_VENUE_AI
              )}
            </div>
            <br />

            <div>
              <strong>Other additional insured</strong>
            </div>
            <div>
              {additionalInsuredText && additionalInsuredText.text ? (
                formatAdditionalInsuredText(additionalInsuredText)
              ) : (
                <span className="additionalInsured--noInfo">{NO_AI_OTHER}</span>
              )}
            </div>
            <p className="mb-4" />
            <p className="label">
              DESCRIPTION OF OPERATIONS / LOCATIONS / VEHICLES
            </p>
            <p>{coverage.descriptionOfOperations}</p>
            <br />

            <div>
              <strong>
                Form 2404 (Waiver of Transfer of Rights of Recovery Against
                Others to Us) included?
              </strong>
            </div>
            <div>{waiverOfTransferRightsIncluded ? "Yes" : "No"}</div>
          </>
        )}
      </div>
    </div>
  );
};

AdditionalInsured.propTypes = {
  coverage: coveragePropType.isRequired,
  editing: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  editFacility: PropTypes.bool.isRequired,
  editVenue: PropTypes.bool.isRequired,
  editVenueAdditionalInsured: PropTypes.bool.isRequired,
  setEditingFacility: PropTypes.func.isRequired,
  setEditingVenue: PropTypes.func.isRequired,
  setEditingVenueAdditionalInsured: PropTypes.func.isRequired,
  resetFacilityAddressForm: PropTypes.func.isRequired,
  resetVenueAddressForm: PropTypes.func.isRequired,
  resetVenueAddressAdditionalInsuredForm: PropTypes.func.isRequired,
  textCounter: PropTypes.string.isRequired,
  facilityAddress: additionalInsuredPropTypes.isRequired,
  venueAddress: additionalInsuredPropTypes.isRequired,
  venueAddressAdditionalInsured: additionalInsuredPropTypes.isRequired,
  waiverOfTransferRightsIncluded: PropTypes.bool.isRequired,
  waiverOfTransferRightsAlwaysIncluded: PropTypes.bool.isRequired,
  clearAddressGroup: PropTypes.func.isRequired,
};

export default AdditionalInsured;
