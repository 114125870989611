import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import { validators } from "@jauntin/utilities";
import { CheckboxInput } from "@jauntin/react-ui";
import { maxFacilityCodeLength } from "@gbli-events/common/src/Constants/codes";
import {
  descriptionOfOperationsMaxLength,
  descriptionOfOperationsMaxLengthValidator,
  facilityCodeValidation,
  facilityOtherAdditionalInsuredZipCode,
} from "../../Helpers/validators";
import {
  additionalInsuredPropType,
  facilityPropType,
} from "../../Helpers/FacilityModel";
import { normalizeFacilityCode, normalizeZip } from "../../normalizer";
import { facilityFormName, statePropType } from "../../constants";
import CountryField from "./FormElements/CountryField";
import FacilityCodeField from "./FormElements/FacilityCodeField";
import Debounce from "../../Helpers/Debounce";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import TextAreaField from "@gbli-events/common/src/Components/FormElements/TextAreaField";
import { getEditFacilityFormImgSrc } from "src/Selectors/Facility";
import FacilityLogoUpload from "./FacilityLogoUpload";
import SelectListField from "@gbli-events/common/src/Components/FormElements/SelectListField/SelectListField";
import { normalizeDescriptionOfOperations } from "@gbli-events/common/src/Helpers/normalizers";
const { required } = validators;

const debouncer = new Debounce({ period: 500 });

const Information = ({
  editing,
  facility,
  validFacilityCode,
  setValidFacilityCode,
  hasCheckedFacilityCode,
  setHasCheckedFacilityCode,
  checkAndSetValidFacilityCode,
  otherInsured,
  states,
  facilityCodeCounter,
}) => {
  const facilityLogoSrc = useSelector(getEditFacilityFormImgSrc);
  const dispatch = useDispatch();

  const selectFacilityLogo = (file) => {
    dispatch(change(facilityFormName, "facilityImgFile", file));
  };

  const clearFacilityLogo = () => {
    dispatch(change(facilityFormName, "facilityImgFile", null));
    dispatch(change(facilityFormName, "facilityLogoUrl", null));
  };

  return (
    <div className="card w-100">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Organization Information</strong>
        </div>
      </div>
      {editing ? (
        <>
          <div className="card-body">
            <Row>
              <Col md={10}>
                <Field
                  component={FacilityCodeField}
                  validate={[required, facilityCodeValidation]}
                  name="facilityCode"
                  entityType="Organization"
                  type="text"
                  normalize={normalizeFacilityCode}
                  maxLength={maxFacilityCodeLength}
                  onChange={(e) => {
                    const normalizedValue = normalizeFacilityCode(
                      e.target.value
                    );
                    if (normalizedValue === facility.code) {
                      setValidFacilityCode(true);
                    }
                    if (
                      normalizedValue &&
                      normalizedValue !== facility.code &&
                      normalizedValue.length <= maxFacilityCodeLength
                    ) {
                      setHasCheckedFacilityCode(false);
                      debouncer.do(
                        checkAndSetValidFacilityCode,
                        normalizedValue,
                        setHasCheckedFacilityCode,
                        setValidFacilityCode
                      );
                    }
                  }}
                  hasCheckedFacilityCode={hasCheckedFacilityCode}
                  validFacilityCode={validFacilityCode}
                />
                <div className="text-right policy--charCount">
                  {facilityCodeCounter}/{maxFacilityCodeLength} characters
                </div>
                {hasCheckedFacilityCode && !validFacilityCode && (
                  <div className="form-row">
                    <div className="col-sm form-group form-error">
                      The Organization Code has already been taken.
                    </div>
                  </div>
                )}
                <div className="my-4">
                  As it should appear on all certificates of insurance:
                </div>
                <Field
                  component={TextField}
                  validate={[required]}
                  name="otherInsured.companyName"
                  label="Organization Name"
                  ariaLabel="Organization Name"
                  type="text"
                  inputClassName="form-control-lg col-lg-6 mb-4"
                  errorClassName="d-inline ml-2"
                />
                <Field
                  component={TextField}
                  name="otherInsured.address1"
                  type="text"
                  label="Street address"
                  ariaLabel="Street address"
                  inputClassName="form-control-lg mb-2"
                  className="mb-0"
                />
                <Field
                  component={TextField}
                  name="otherInsured.address2"
                  type="text"
                  ariaLabel="Address Line 2"
                  inputClassName="form-control-lg mb-2"
                />
                <div className="form-row">
                  <div className="col-sm">
                    <Field
                      component={TextField}
                      name="otherInsured.city"
                      type="text"
                      label="City"
                      ariaLabel="City"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      name="otherInsured.state"
                      component={SelectListField}
                      options={states.map(({ name, code }) => ({
                        label: name,
                        value: code,
                      }))}
                      searchable
                      label="State"
                      placeholder="State"
                      resetValue={""}
                    />
                  </div>
                  <div className="col-sm">
                    <Field
                      component={TextField}
                      validate={[facilityOtherAdditionalInsuredZipCode]}
                      normalize={normalizeZip}
                      name="otherInsured.zip"
                      type="text"
                      label="Zipcode"
                      ariaLabel="Zip"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div hidden>
                    <Field
                      name="otherInsured.country"
                      component={CountryField}
                      validate={[required]}
                      type="select"
                      input={{ disabled: true }}
                      hidden="true"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <div className="mt-3 form-row">
                  <div className="col-sm form-group">
                    <Field
                      component={CheckboxInput}
                      name="hideFacilityInfo"
                      type="checkbox"
                      className="d-flex align-items-center"
                      ariaLabel="Do not print Organization Information on Certificates checkbox"
                      label="Do not print Organization Information on Certificates"
                      labelClassName="pl-2 label hideFacilityInfo"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="mt-3 form-row">
                  <div className="col-sm form-group">
                    <Field
                      component={TextAreaField}
                      normalize={normalizeDescriptionOfOperations}
                      validate={[
                        required,
                        descriptionOfOperationsMaxLengthValidator,
                      ]}
                      maxLength={descriptionOfOperationsMaxLength}
                      name="descriptionOfOperations"
                      label="DESCRIPTION OF OPERATIONS / LOCATIONS / VEHICLES"
                      rows={4}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <FacilityLogoUpload
              imgSrc={facilityLogoSrc}
              onSelect={selectFacilityLogo}
              onClear={clearFacilityLogo}
            />
          </div>
        </>
      ) : (
        <>
          <div className="card-body">
            <p className="label">Organization ID</p>
            <p className="mb-4">{facility.code}</p>

            <p className="label">Organization Name</p>
            <p className="mb-4">{otherInsured.companyName}</p>

            {!!otherInsured.id && (
              <p>
                <span className="d-flex">
                  {`${otherInsured.address1}${
                    otherInsured.address1 && otherInsured.address2 ? `,` : ""
                  } ${otherInsured.address2}`}
                </span>
                <span className="d-flex">
                  {`${otherInsured.city}${
                    otherInsured.city && otherInsured.state ? `,` : ""
                  } ${otherInsured.state} ${otherInsured.zip}`}
                </span>
              </p>
            )}
            <p className="mb-4" />
            <p className="label">
              Do not print Organization Information on Certificates
            </p>
            <p>{facility.hideFacilityInfo ? "Yes" : "No"}</p>
            <p className="mb-4" />
            <p className="label">
              DESCRIPTION OF OPERATIONS / LOCATIONS / VEHICLES
            </p>
            <p>{facility.descriptionOfOperations}</p>

            {facility.logoUrl && (
              <div className="mt-3">
                <p className="label">Organization Logo</p>
                <div className="d-flex align-items-center justify-content-center py-4 my-3 border">
                  <img src={facility.logoUrl} alt="Organization Logo" />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Information.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
  validFacilityCode: PropTypes.bool.isRequired,
  setValidFacilityCode: PropTypes.func.isRequired,
  hasCheckedFacilityCode: PropTypes.bool.isRequired,
  setHasCheckedFacilityCode: PropTypes.func.isRequired,
  checkAndSetValidFacilityCode: PropTypes.func.isRequired,
  otherInsured: additionalInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  facilityCodeCounter: PropTypes.number.isRequired,
};

export default Information;
