import cc from "card-validator";
import { dateHelpers } from "@jauntin/utilities";
import { toTitleCase } from "./StringHelper";

// Phone number
export const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

const normalizeNdigits = (value, digits) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums.slice(0, digits);
};

// Zip code
export const normalizeZip = (value) => {
  return normalizeNdigits(value, 5);
};

export const normalizeZipPlus4 = (value) => {
  let normalized = value.replace(/[^\d]/g, "");
  if (normalized.length > 5) {
    normalized = `${normalized.slice(0, 5)}-${normalized.slice(5)}`;
  }
  return normalized.slice(0, 10);
};

export const normalizeDigits = (digits) => (value) =>
  normalizeNdigits(value, digits);

// Email
export const normalizeEmail = (value) => value && value.toLowerCase();

export const normalizeCreditCard = (value) => {
  const onlyNums = value.replace(/[^\d]/g, "");
  if (!onlyNums) return onlyNums;
  const card = cc.number(onlyNums);

  let out = onlyNums;
  let gaps = [];
  let length = 19;
  if (card.card) {
    if (card.card.lengths) {
      length = card.card.lengths.reduce((previous, current) =>
        Math.max(previous, current)
      );
    }
    if (card.card.gaps) {
      gaps = card.card.gaps.reverse();
    }
  }

  out = out.substring(0, length);
  gaps.forEach((gap) => {
    if (out.length > gap) {
      out = `${out.substring(0, gap)} ${out.substring(gap)}`;
    }
  });
  return out;
};

export const normalizeCCDate = (value) => {
  const onlyNums = value.replace(/[^\d]/g, "");
  let out = onlyNums.substring(0, 4);
  if (out.length > 2) {
    out = `${out.substring(0, 2)}/${out.substring(2)}`;
  }
  return out;
};

export const normalizeYear = (twoDigitYear) => {
  // Better method for this?
  return `20${twoDigitYear}`;
};

export const extractMonthYear = (expiryDate) => {
  return cc.expirationDate(expiryDate);
};

export const normalizeCVV = (value, _, allValues) => {
  const number = allValues.payeeCardNum;
  const card = cc.number(number);
  let length = 3;
  if (card.card && card.card.code) {
    length = card.card.code.size;
  }
  let out = value.replace(/[^\d]/g, "");
  out = out.substring(0, length);
  return out;
};

export const normalizeEventName = (value) => {
  const maxLength = 50;

  if (!value) return value;

  const newValue = value.slice(0, maxLength);

  return newValue;
};

export const normalizeFacilityCode = (value) => {
  if (!value) return value;

  const onlyAlphaNumeric = value.replace(/[^A-Za-z0-9]/g, "");

  return onlyAlphaNumeric.slice(0, 4).toUpperCase();
};

export const normalizeVenueCode = (value) => {
  if (!value) return value;

  const onlyNums = value.replace(/[^\d]/g, "");

  return onlyNums.slice(0, 5);
};

export const normalizeProviderDays = (value, _, allValues) => {
  if (!value) return value;

  const totalDays = dateHelpers.numberOfDays(allValues.eventDates);
  const onlyNums = value.replace(/[^\d]|^(0*)/g, "");

  if (onlyNums > totalDays) return totalDays.toString();

  return onlyNums;
};

export const trimLeading = (value) => {
  if (!value) return value;

  const trimText = value.replace(/^\s+/g, "");

  return trimText;
};

export const normalizeTaxToString = ({ state, type, code, municipalName }) => {
  const taxTypeTranslation = {
    AdChrg: "Additional Charge",
  };

  const taxCodeTranslation = {
    TAX: "Tax",
  };

  const typeLabel =
    state === "KY"
      ? municipalName
        ? "Municipal Tax"
        : "State Tax"
      : `${taxTypeTranslation[type] || type} ${
          taxCodeTranslation[code] || code
        }`;

  return `${state} ${typeLabel}${
    municipalName ? " (" + toTitleCase(municipalName) + ")" : ""
  }`;
};

export const normalizeDescriptionOfOperations = (value) => {
  return value.replace(/[\n\r]/g, "");
};
