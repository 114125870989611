import React, { useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { ConnectedRouter } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";
import download from "downloadjs";
import {
  reduxForm,
  reset,
  change,
  initialize,
  formValueSelector,
  getFormValues,
} from "redux-form";
import { getUrl, PRODUCER_PAGE } from "src/Helpers/URLParser";
import {
  setFormValues,
  getPolicy,
  toggleModal,
  deleteDate as deleteDateAction,
  clearVenueMunicipalityCode,
  selectPlace,
  shouldLookupTax,
  checkAndSetVenue,
  updateVenueInputSelect,
  searchByVenueCode,
  clearSpecialTaxForms,
  clearVenueSearchForm,
  resetVenueFields,
  setIsFormPristine,
  setIsPristine as setIsPristineAction,
  getCoordsForUtc,
  setKentuckyCity as setKentuckyCityAction,
  confirmUpdatePolicy,
  batchUpdateFormFields,
  clearFieldErrors,
} from "../../Actions/actions";
import BasicCoverage from "./BasicCoverage";
import Event from "./Event";
import AdditionalCoverage from "./AdditionalCoverage";
import AdditionalInsured from "./AdditionalInsured";
import Payment from "./Payment";
import { Button } from "@jauntin/react-ui";
import {
  policyFormName,
  statePropType,
  KENTUCKY_STATE_CODE,
  TYPE_FACILITY,
  TYPE_VENUE_ADDRESS,
  TYPE_VENUE,
  TYPE_TEXT,
  additionalInsuredPropTypes,
  FACILITY_ADDRESS_GROUP_NAME,
} from "../../constants";
import {
  coveragePropType,
  getAdditionalInsuredInfo,
  initialOptionalVenueFormValues,
} from "../../Helpers/CoverageModel";
import Insured from "./Insured";
import Venue from "./Venue";
import CoverageService from "../../Helpers/CoverageService";
import { canChange, canCancel } from "../../Helpers/PolicyChangeBusinessRules";
import {
  venueSearchInputOptions,
  venueSearchPropType,
  BY_MANUAL_ADDRESS,
  BY_VENUE_CODE,
  BY_SEARCH,
} from "../../Helpers/VenueSearchTypes";
import API from "../../Helpers/API";
import ModalUpdate from "./ModalUpdate";
import ModalCancel from "./ModalCancel";
import Debounce from "../../Helpers/Debounce";
import InsuranceContact from "./InsuranceContact";
import {
  downloadDocument,
  getDocumentsStatus,
  downloadTransactionsJson,
  downloadPolicyCsv,
  downloadUploadedDocs,
} from "../../Helpers/DocumentDownload";
import { GLL_1 } from "@gbli-events/common/src/Constants/limits";
import {
  getOptionalVenuesForUpdate,
  getVenueStatesMatch,
  getHasVenueStateCannabisError,
} from "src/Selectors/Venue";
import Producer from "src/Containers/Policy/Producer";
import { getSetupAndTeardownDates } from "@gbli-events/common/src/Reducers/formReducers";
import { usePollDocuments } from "@jauntin/react-ui";
import PopoverTrigger from "src/Components/SearchEllipsisPopover";
import { isEmpty } from "lodash";
import { canViewReports } from "../../Selectors/Users";

const AlertPremiumChange = ({ show }) => (
  <Alert show={show} variant="danger" className="mx-auto mb-0 mt-3">
    <div className="form-row">
      <div className="col-auto">
        <i className="fal fa-info-circle icon--large" />
      </div>
      <div className="col">
        This edit requires a change in premium and cannot be saved. To make this
        change, please cancel the policy and have the customer repurchase a
        policy with different parameters.
      </div>
    </div>
  </Alert>
);
AlertPremiumChange.propTypes = {
  show: PropTypes.bool.isRequired,
};

const AlertVenueGllChange = ({ show }) => (
  <Alert show={show} variant="danger" className="mx-auto mb-0 mt-3">
    <div className="form-row">
      <div className="col-auto">
        <i className="fal fa-info-circle icon--large" />
      </div>
      <div className="col">
        Selected venue doesn't allow current policy General Liability Coverage
        (Occurrence & Aggregate). To make this change, please cancel the policy
        and have the customer repurchase a policy with different venue.
      </div>
    </div>
  </Alert>
);
AlertVenueGllChange.propTypes = {
  show: PropTypes.bool.isRequired,
};

const AlertVenueDrpChange = ({ show }) => (
  <Alert show={show} variant="danger" className="mx-auto mb-0 mt-3">
    <div className="form-row">
      <div className="col-auto">
        <i className="fal fa-info-circle icon--large" />
      </div>
      <div className="col">
        Selected venue doesn't allow current policy Damage to Rented Premises
        coverage. To make this change, please cancel the policy and have the
        customer repurchase a policy with different venue.
      </div>
    </div>
  </Alert>
);
AlertVenueDrpChange.propTypes = {
  show: PropTypes.bool.isRequired,
};

const PopoverContent = ({
  coverage,
  downloadCsv,
  downloadTransactions,
  downloadUploadedDocuments,
}) => (
  <>
    <div className="mb-2">
      <Button
        className="btn popover-item__btn"
        onClick={() => {
          downloadTransactions(
            coverage,
            `${coverage.policyNumberGll}_${process.env.REACT_APP_ENV}_transactions.zip`
          );
        }}
        text={
          <>
            <i className="fal fa-code mr-2 col-1 p-0 "></i>
            <span className="col-auto p-0">Download Transactions JSON</span>
          </>
        }
      ></Button>
    </div>
    <div className="mb-2">
      <Button
        className="btn popover-item__btn"
        onClick={() => {
          downloadCsv(
            coverage,
            `${coverage.policyNumberGll}_${process.env.REACT_APP_ENV}_report.csv`
          );
        }}
        text={
          <>
            <i className="fal fa-file-csv mr-2 col-1 p-0 "></i>
            <span className="col-auto p-0">Download CSV Report</span>
          </>
        }
      ></Button>
    </div>
    <div className="mb-2">
      <Button
        className="btn popover-item__btn"
        onClick={() => {
          downloadUploadedDocuments(
            coverage,
            `${coverage.policyNumberGll}_${process.env.REACT_APP_ENV}_document-uploads.zip`
          );
        }}
        text={
          <>
            <i className="fal fa-file-csv mr-2 col-1 p-0 "></i>
            <span className="col-auto p-0">Document Uploads</span>
          </>
        }
      ></Button>
    </div>
  </>
);

PopoverContent.propTypes = {
  coverage: coveragePropType.isRequired,
  downloadCsv: PropTypes.func.isRequired,
  downloadTransactions: PropTypes.func.isRequired,
  downloadUploadedDocuments: PropTypes.func.isRequired,
};

const formValues = formValueSelector(policyFormName);
const allFormValues = getFormValues(policyFormName);
const scrollToPosition = (ref) =>
  ref.current.scrollIntoView({ behavior: "smooth" });
const debounce = new Debounce({ period: 500 });

const addressBlock = (address) => ({
  companyName: (address && address.companyName) || "",
  address1: (address && address.address1) || "",
  address2: (address && address.address2) || "",
  city: (address && address.city) || "",
  state: (address && address.state) || "",
  country: (address && address.country) || "US",
  zip: (address && address.zip) || "",
});

const addressFormBlock = (state, formGroup) => {
  const address = {
    companyName: formValues(state, `${formGroup}.companyName`),
    address1: formValues(state, `${formGroup}.address1`),
    address2: formValues(state, `${formGroup}.address2`),
    city: formValues(state, `${formGroup}.city`),
    state: formValues(state, `${formGroup}.state`),
    country: formValues(state, `${formGroup}.country`),
    zip: formValues(state, `${formGroup}.zip`),
  };

  return Object.values(address).reduce(
    (prev, curr) => (curr ? true : prev),
    false
  )
    ? address
    : null;
};

const compileAdditionalInsureds = (values, addresses, additionalInsureds) =>
  Object.keys(addresses).forEach((address) => {
    additionalInsureds.push({
      id: addresses[address].id || "",
      type: addresses[address].type,
      address: values[address],
    });
    return additionalInsureds;
  });

const Policy = ({
  editReset,
  update,
  cancel,
  usStates,
  coverage,
  latestOrderNumber,
  eventFrequency,
  eventDates,
  eventSetupDates,
  eventTeardownDates,
  showListOfDates,
  deleteDate,
  showDatePicker,
  blockedDays,
  showCustomDatePicker,
  canUpdatePolicy,
  renterAddressType,
  insuredCompanyType,
  insuranceContactType,
  contactSame,
  valid,
  onSelectVenue,
  clearForm,
  hasInput,
  showModalUpdate,
  showModalCancel,
  modalToggle,
  resetVenueAddressForm,
  resetFacilityAddressForm,
  resetVenueAddressAdditionalInsuredForm,
  resetOtherAdditionalInsuredForm,
  resetProducerForm,
  isValidAddress,
  isValidState,
  isKentuckyEntity,
  additionalInsured,
  venueSearchOptions,
  venueSearchTypeRadio,
  venueInputSelect,
  searchVenueCode,
  facilityCode,
  hideFacilityInfo,
  venueCode,
  lookupTax,
  venueName,
  venueCompanyName,
  venueAddress1,
  venueCity,
  venueState,
  venueCountry,
  venueZip,
  venueGll,
  manualVenueCompanyName,
  manualVenueAddress,
  manualVenueCity,
  manualVenueState,
  manualVenueCountry,
  manualVenueZip,
  isPristine,
  pristine,
  lookupUtc,
  isValidManualAddress,
  isValidGll,
  isValidDrp,
  setKentuckyCity,
  clearTaxForm,
  setCanUpdatePolicy,
  setIsPristine,
  facilityAddressFormValues,
  venueAddressFormValues,
  venueAddressAdditionalInsuredFormValues,
  waiverOfTransferRightsIncluded,
  waiverOfTransferRightsAlwaysIncluded,
  isBlocked,
  clearAddressGroup,
  isValidAdditionalInsuredVenue,
  policyDocumentFilename,
  goToProducer,
  producer,
  isInSameMunicipality,
  isValidOptionalVenueAddress,
  isUniqueVenueAddresses,
  canViewReports,
}) => {
  const [editing, setEditing] = useState(false);
  const [expandDateList, setExpandDateList] = useState(false);
  const [editFacility, setEditingFacility] = useState(false);
  const [editVenue, setEditingVenue] = useState(false);
  const [editVenueAdditionalInsured, setEditingVenueAdditionalInsured] =
    useState(false);
  const [hasSelectedVenue, setHasSelectedVenue] = useState(false);
  const [hasRegionalTax, setHasRegionalTax] = useState(false);
  const [taxRegions, setTaxRegions] = useState([]);
  const [venueCodeChecked, setVenueCodeChecked] = useState(false);
  const [validVenueCode, setValidVenueCode] = useState(true);
  const [isValidCity, setIsValidCity] = useState(true);
  const [hasLookedUpTax, setHasLookedUpTax] = useState(true);
  const [kentuckyCity, getKentuckyCity] = useState("");
  const [venueSearchReadOnly, setVenueSearchReadOnly] = useState(false);
  const eventRef = useRef(null);
  const isManualAddress = () =>
    venueSearchTypeRadio === BY_MANUAL_ADDRESS && isValidManualAddress;
  const [showCancelRefundRequired, setShowCancelRefundRequired] =
    useState(false);
  const [waiting, setWaiting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const manualVenueAddressFormValues = {
    companyName: manualVenueCompanyName,
    address1: manualVenueAddress,
    address2: "",
    city: manualVenueCity,
    state: manualVenueState,
    zip: manualVenueZip,
    country: manualVenueCountry,
  };

  const venueStatesMatch = useSelector(getVenueStatesMatch);
  const hasVenueStateCannabisError = useSelector(getHasVenueStateCannabisError);
  const { downloading, startDownload } = usePollDocuments({
    getDocumentsStatus: () => getDocumentsStatus(latestOrderNumber),
    getDocuments: () => downloadDocument(coverage),
    downloadFn: download,
    fileDownloadName: policyDocumentFilename,
  });

  return (
    <>
      <div className="content__header col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Policy</h4>
          <div className="d-flex float-right">
            {!editing && (
              <>
                <div className="policy__document text-right mx-4">
                  {downloading && (
                    <span
                      className="policy__download spinner-border spinner-border-sm align-middle mr-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <Button
                    className="btn btn-link policy__download"
                    text="Download Latest Policy Document"
                    onClick={() => {
                      setTimeout(startDownload, 2000);
                    }}
                  />
                  <div className="policy__updated">
                    <em>
                      {`Updated on ${dateHelpers.lettersAndNumbers(
                        coverage.updated
                      )} at ${dateHelpers.simpleTime(coverage.updated)}`}
                    </em>
                  </div>
                </div>
                {canViewReports &&
                  process.env.REACT_APP_ENV !== "production" && (
                    <div className="mr-4">
                      <PopoverTrigger
                        placement="left"
                        styleClass="d-inline-block"
                        content={
                          <PopoverContent
                            coverage={coverage}
                            downloadCsv={downloadPolicyCsv}
                            downloadTransactions={downloadTransactionsJson}
                            downloadUploadedDocuments={downloadUploadedDocs}
                          />
                        }
                      />
                    </div>
                  )}
              </>
            )}
            {(editing && (
              <>
                <Button
                  onClick={() => {
                    setEditing(false);
                    setEditingFacility(false);
                    setEditingVenue(false);
                    setEditingVenueAdditionalInsured(false);
                    setExpandDateList(false);
                    setHasSelectedVenue(false);
                    setHasRegionalTax(false);
                    setTaxRegions([]);
                    setHasLookedUpTax(true);
                    setVenueSearchReadOnly(false);
                    editReset();
                  }}
                  text="Discard"
                  className="btn btn-outline-secondary px-4 mx-4"
                />
                {canChange(coverage) && (
                  <Button
                    onClick={() => {
                      modalToggle("update", true);
                      setExpandDateList(false);
                      setHasSelectedVenue(false);
                      setHasRegionalTax(false);
                      setVenueCodeChecked(false);
                      setTaxRegions([]);
                    }}
                    disabled={
                      !valid ||
                      !canChange(coverage) ||
                      !canUpdatePolicy ||
                      !isValidAddress() ||
                      !isValidState() ||
                      isPristine ||
                      pristine ||
                      !hasLookedUpTax ||
                      !isValidCity ||
                      (venueSearchTypeRadio === BY_VENUE_CODE &&
                        !validVenueCode) ||
                      (venueSearchTypeRadio === BY_MANUAL_ADDRESS &&
                        !isValidManualAddress) ||
                      !isValidGll ||
                      !isValidDrp ||
                      isBlocked ||
                      !isValidAdditionalInsuredVenue ||
                      !venueStatesMatch ||
                      hasVenueStateCannabisError ||
                      !isInSameMunicipality ||
                      !isValidOptionalVenueAddress() ||
                      !isUniqueVenueAddresses()
                    }
                    text="Update Policy"
                    className="btn btn-primary px-4"
                  />
                )}
              </>
            )) ||
              (canChange(coverage) && (
                <Button
                  onClick={() => {
                    setEditing(true);
                    editReset();
                    scrollToPosition(eventRef);
                    setExpandDateList(false);
                  }}
                  text="Edit Policy"
                  className="btn btn-primary px-4"
                />
              ))}
          </div>
        </div>
        <AlertPremiumChange
          show={
            editing &&
            ((!canUpdatePolicy && !pristine && isValidCity) ||
              !isInSameMunicipality)
          }
        />
        <AlertVenueGllChange
          show={editing && canUpdatePolicy && !pristine && isValidGll === false}
        />
        <AlertVenueDrpChange
          show={editing && canUpdatePolicy && !pristine && isValidDrp === false}
        />
        {showCancelRefundRequired && (
          <Alert variant="danger" className="mx-auto mb-0 mt-3">
            <div className="form-row">
              <div className="col-auto">
                <i className="fal fa-info-circle icon--large" />
              </div>
              <div className="col">Manual refund required.</div>
            </div>
          </Alert>
        )}
      </div>
      <div className="content__body">
        <BasicCoverage
          coverage={coverage}
          editing={editing}
          eventRef={eventRef}
        />
        <Event
          coverage={coverage}
          eventFrequency={eventFrequency}
          eventDates={eventDates}
          eventSetupDates={eventSetupDates}
          eventTeardownDates={eventTeardownDates}
          showListOfDates={showListOfDates}
          deleteDate={deleteDate}
          showDatePicker={showDatePicker}
          blockedDays={blockedDays}
          showCustomDatePicker={showCustomDatePicker}
          editing={editing}
          expandDateList={expandDateList}
          setExpandDateList={setExpandDateList}
        />
        <AdditionalCoverage coverage={coverage} />
        <Insured
          editing={editing}
          coverage={coverage}
          renterAddressType={renterAddressType}
          insuredCompanyType={insuredCompanyType}
          states={usStates}
        />
        <InsuranceContact
          editing={editing}
          states={usStates}
          coverage={coverage}
          insuranceContactType={insuranceContactType}
          contactSame={contactSame}
        />
        <Venue
          coverage={coverage}
          editing={editing}
          onSelect={(placeId, address, addressComponents, utcOffset) =>
            onSelectVenue(
              placeId,
              address,
              addressComponents,
              utcOffset,
              setHasSelectedVenue,
              setHasRegionalTax,
              setTaxRegions,
              setIsValidCity,
              setHasLookedUpTax,
              setVenueSearchReadOnly
            )
          }
          clearForm={() =>
            clearForm(
              setHasSelectedVenue,
              setHasRegionalTax,
              setTaxRegions,
              setVenueCodeChecked,
              setValidVenueCode,
              setHasLookedUpTax,
              setVenueSearchReadOnly,
              resetFacilityAddressForm,
              resetProducerForm,
              resetVenueAddressForm,
              resetVenueAddressAdditionalInsuredForm,
              resetOtherAdditionalInsuredForm,
              coverage
            )
          }
          hasInput={hasInput}
          hasRegionalTax={hasRegionalTax}
          setHasRegionalTax={setHasRegionalTax}
          setTaxRegions={setTaxRegions}
          taxRegions={taxRegions}
          isKentuckyEntity={isKentuckyEntity}
          isValidAddress={isValidAddress}
          isValidState={isValidState}
          setHasSelectedVenue={setHasSelectedVenue}
          hasSelectedVenue={hasSelectedVenue}
          venueSelectInputOptions={venueSearchOptions}
          venueSearchTypeRadio={venueSearchTypeRadio}
          venueInputSelect={venueInputSelect}
          setVenueCodeChecked={setVenueCodeChecked}
          venueCodeChecked={venueCodeChecked}
          setValidVenueCode={setValidVenueCode}
          validVenueCode={validVenueCode}
          searchVenueCode={searchVenueCode}
          facilityCode={facilityCode}
          venueCode={venueCode}
          usStates={usStates}
          lookupTax={lookupTax}
          venueName={venueName}
          venueCompanyName={venueCompanyName}
          venueAddress1={venueAddress1}
          venueCity={venueCity}
          venueState={venueState}
          venueCountry={venueCountry}
          venueZip={venueZip}
          venueGll={venueGll}
          manualVenueCompanyName={manualVenueCompanyName}
          manualVenueAddress={manualVenueAddress}
          manualVenueCity={manualVenueCity}
          manualVenueState={manualVenueState}
          manualVenueCountry={manualVenueCountry}
          manualVenueZip={manualVenueZip}
          lookupUtc={lookupUtc}
          isValidCity={isValidCity}
          setIsValidCity={setIsValidCity}
          setHasLookedUpTax={setHasLookedUpTax}
          getKentuckyCity={getKentuckyCity}
          clearTaxForm={clearTaxForm}
          setCanUpdatePolicy={setCanUpdatePolicy}
          setIsPristine={setIsPristine}
          venueSearchReadOnly={venueSearchReadOnly}
          isBlocked={isBlocked}
          pristine={pristine}
        />
        {coverage.producer && (
          <Producer producer={producer} goToProducer={goToProducer} />
        )}
        <AdditionalInsured
          coverage={coverage}
          editing={editing}
          states={usStates}
          editFacility={editFacility}
          editVenue={editVenue}
          editVenueAdditionalInsured={editVenueAdditionalInsured}
          setEditingFacility={setEditingFacility}
          setEditingVenue={setEditingVenue}
          setEditingVenueAdditionalInsured={setEditingVenueAdditionalInsured}
          resetVenueAddressForm={resetVenueAddressForm}
          resetFacilityAddressForm={resetFacilityAddressForm}
          resetVenueAddressAdditionalInsuredForm={
            resetVenueAddressAdditionalInsuredForm
          }
          resetOtherAdditionalInsuredForm={resetOtherAdditionalInsuredForm}
          textCounter={additionalInsured}
          facilityAddress={hideFacilityInfo ? "" : facilityAddressFormValues}
          venueAddress={
            isManualAddress()
              ? manualVenueAddressFormValues
              : venueAddressFormValues
          }
          venueAddressAdditionalInsured={
            venueAddressAdditionalInsuredFormValues
          }
          waiverOfTransferRightsIncluded={waiverOfTransferRightsIncluded}
          waiverOfTransferRightsAlwaysIncluded={
            waiverOfTransferRightsAlwaysIncluded
          }
          clearAddressGroup={clearAddressGroup}
        />
        <Payment coverage={coverage} />

        <div className="form-group text-right py-5">
          {canCancel(coverage) && (
            <Button
              className="btn btn-outline-secondary"
              type="button"
              text="Cancel Policy"
              onClick={() => modalToggle("cancel", true)}
            />
          )}
        </div>
      </div>
      <ModalUpdate
        show={showModalUpdate}
        text={`Update coverage for "${
          coverage.eventName
        }" ${dateHelpers.slashSeparated(
          coverage.effectiveStartDate
        )} - ${dateHelpers.slashSeparated(coverage.effectiveEndDate)}?`}
        action={() => {
          if (
            venueSearchTypeRadio === BY_MANUAL_ADDRESS &&
            kentuckyCity &&
            manualVenueState === KENTUCKY_STATE_CODE
          ) {
            setKentuckyCity(kentuckyCity);
          }
          modalToggle("update", false);
          setUpdating(true);
          update()
            .then(() => {
              setEditing(false);
              setEditingFacility(false);
              setEditingVenue(false);
              setEditingVenueAdditionalInsured(false);
              setVenueSearchReadOnly(false);
            })
            .finally(() => {
              setUpdating(false);
            });
        }}
        updating={updating}
        closeAction={() => modalToggle("update", false)}
      />
      <ModalCancel
        show={showModalCancel}
        text={`Cancel coverage for "${
          coverage.eventName
        }" ${dateHelpers.slashSeparated(
          coverage.effectiveStartDate
        )} - ${dateHelpers.slashSeparated(
          coverage.effectiveEndDate
        )}? This action will refund the full premium and automatically notify the insured and venue of the cancellation.`}
        action={() => {
          setWaiting(true);
          cancel()
            .then((response) => {
              setShowCancelRefundRequired(response.refunded === false);
              setEditing(false);
              setEditingFacility(false);
              setEditingVenue(false);
              setEditingVenueAdditionalInsured(false);
              modalToggle("cancel", false);
            })
            .finally(() => {
              setWaiting(false);
            });
        }}
        waiting={waiting}
        closeAction={() => modalToggle("cancel", false)}
      />
    </>
  );
};

Policy.propTypes = {
  update: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  usStates: PropTypes.arrayOf(statePropType).isRequired,
  coverage: coveragePropType.isRequired,
  latestOrderNumber: PropTypes.number.isRequired,
  eventFrequency: PropTypes.string.isRequired,
  eventDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventSetupDates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.any,
    })
  ).isRequired,
  eventTeardownDates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.any,
    })
  ).isRequired,
  showListOfDates: PropTypes.bool.isRequired,
  deleteDate: PropTypes.func.isRequired,
  showDatePicker: PropTypes.bool.isRequired,
  blockedDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  showCustomDatePicker: PropTypes.bool.isRequired,
  canUpdatePolicy: PropTypes.bool.isRequired,
  renterAddressType: PropTypes.string.isRequired,
  insuredCompanyType: PropTypes.string.isRequired,
  insuranceContactType: PropTypes.string.isRequired,
  contactSame: PropTypes.string.isRequired,
  onSelectVenue: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  hasInput: PropTypes.bool.isRequired,
  showModalUpdate: PropTypes.bool.isRequired,
  showModalCancel: PropTypes.bool.isRequired,
  modalToggle: PropTypes.any.isRequired,
  resetFacilityAddressForm: PropTypes.func.isRequired,
  resetVenueAddressForm: PropTypes.func.isRequired,
  resetVenueAddressAdditionalInsuredForm: PropTypes.func.isRequired,
  resetOtherAdditionalInsuredForm: PropTypes.func.isRequired,
  resetProducerForm: PropTypes.func.isRequired,
  isValidAddress: PropTypes.func.isRequired,
  isValidOptionalVenueAddress: PropTypes.func.isRequired,
  isUniqueVenueAddresses: PropTypes.func.isRequired,
  hideFacilityInfo: PropTypes.bool.isRequired,
  isValidState: PropTypes.func.isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  additionalInsured: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  venueSearchOptions: PropTypes.arrayOf(venueSearchPropType).isRequired,
  venueSearchTypeRadio: PropTypes.string.isRequired,
  venueInputSelect: PropTypes.func.isRequired,
  searchVenueCode: PropTypes.func.isRequired,
  facilityCode: PropTypes.string.isRequired,
  venueCode: PropTypes.string.isRequired,
  lookupTax: PropTypes.func.isRequired,
  venueName: PropTypes.string.isRequired,
  venueCompanyName: PropTypes.string.isRequired,
  venueAddress1: PropTypes.string.isRequired,
  venueCity: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  venueCountry: PropTypes.string.isRequired,
  venueZip: PropTypes.string.isRequired,
  venueGll: PropTypes.shape({
    occurrence: PropTypes.number.isRequired,
    aggregate: PropTypes.number.isRequired,
  }).isRequired,
  manualVenueCompanyName: PropTypes.string.isRequired,
  manualVenueAddress: PropTypes.string.isRequired,
  manualVenueCity: PropTypes.string.isRequired,
  manualVenueState: PropTypes.string.isRequired,
  manualVenueCountry: PropTypes.string.isRequired,
  manualVenueZip: PropTypes.string.isRequired,
  isPristine: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  lookupUtc: PropTypes.func.isRequired,
  isValidManualAddress: PropTypes.bool.isRequired,
  isValidGll: PropTypes.bool.isRequired,
  isValidDrp: PropTypes.bool.isRequired,
  federalEntity: PropTypes.string.isRequired,
  kentuckyStateEntity: PropTypes.string.isRequired,
  setKentuckyCity: PropTypes.func.isRequired,
  clearTaxForm: PropTypes.func.isRequired,
  setCanUpdatePolicy: PropTypes.func.isRequired,
  setIsPristine: PropTypes.func.isRequired,
  facilityAddressFormValues: additionalInsuredPropTypes.isRequired,
  venueAddressFormValues: additionalInsuredPropTypes.isRequired,
  venueAddressAdditionalInsuredFormValues:
    additionalInsuredPropTypes.isRequired,
  waiverOfTransferRightsIncluded: PropTypes.bool.isRequired,
  waiverOfTransferRightsAlwaysIncluded: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  clearAddressGroup: PropTypes.func.isRequired,
  isValidAdditionalInsuredVenue: PropTypes.bool.isRequired,
  policyDocumentFilename: PropTypes.string.isRequired,
  producer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    subProducerCode: PropTypes.string,
  }).isRequired,
  isInSameMunicipality: PropTypes.bool.isRequired,
  eventTypeName: PropTypes.string.isRequired,
  canViewReports: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const eventFrequency = formValues(state, "eventFrequencyField");
  const blockedDays =
    eventFrequency === "weekly"
      ? dateHelpers.pickedToBlockedDays(formValues(state, "daysOfWeekField"))
      : [];
  const { currentCoverage } = state.policies;
  const kentuckyEntity = formValues(state, "kentuckyStateEntity")
    ? formValues(state, "kentuckyStateEntity") === "yes"
    : currentCoverage.isKentuckyEntity || true;
  const currentFacilityAddress = getAdditionalInsuredInfo(
    currentCoverage,
    TYPE_FACILITY
  );
  const currentVenueAddress = getAdditionalInsuredInfo(
    currentCoverage,
    TYPE_VENUE_ADDRESS
  );
  const currentVenueAddressAdditionalInsured = getAdditionalInsuredInfo(
    currentCoverage,
    TYPE_VENUE
  );
  const facilityAddressFormValues = addressFormBlock(state, "facilityAddress");
  const venueAddressFormValues = addressFormBlock(state, "venueAddress");
  const venueAddressAdditionalInsuredFormValues = addressFormBlock(
    state,
    "venueAddressAdditionalInsured"
  );

  const facilityAddress =
    facilityAddressFormValues || currentFacilityAddress || {};
  const venueAddress = venueAddressFormValues || currentVenueAddress || {};
  const venueAddressAdditionalInsured =
    venueAddressAdditionalInsuredFormValues ||
    currentVenueAddressAdditionalInsured ||
    {};

  const producer = currentCoverage.referralProducer
    ? currentCoverage.producer
    : formValues(state, "producer") || currentCoverage.producer;

  const usStates = state.app.states;
  const policy = state.form.policy;

  return {
    usStates,
    coverage: currentCoverage,
    latestOrderNumber: currentCoverage.latestOrderNumber,
    eventFrequency: eventFrequency || currentCoverage.eventFrequencyField,
    eventDates:
      formValues(state, "eventDates") ||
      (currentCoverage || { eventDates: [] }).eventDates,
    eventSetupDates: formValues(state, "eventSetupDates") || [],
    eventTeardownDates: formValues(state, "eventTeardownDates") || [],
    showListOfDates: eventFrequency !== "continuous",
    showDatePicker:
      eventFrequency === "continuous" ||
      (eventFrequency === "weekly" && blockedDays.length < 7),
    blockedDays,
    showCustomDatePicker: eventFrequency === "custom",
    canUpdatePolicy: state.policies.canUpdatePolicy,
    renterAddressType:
      formValues(state, "renterAddressType") ||
      (currentCoverage || { renterAddressType: "" }).renterAddressType,
    insuredCompanyType:
      formValues(state, "insuredCompanyType") ||
      (currentCoverage || { insuredCompanyType: "Individual" })
        .insuredCompanyType,
    insuranceContactType:
      formValues(state, "insuranceContactType") ||
      (currentCoverage || { insuranceContactType: "" }).insuranceContactType,
    contactSame:
      formValues(state, "contactSame") || currentCoverage.contactSame,
    showModalUpdate: state.policies.modal.update,
    showModalCancel: state.policies.modal.cancel,
    venueSearchOptions: venueSearchInputOptions,
    venueSearchTypeRadio:
      formValues(state, "venueSearchTypeRadio") ||
      currentCoverage.venueSearchTypeRadio ||
      BY_SEARCH,
    venueName: (state.form.policy.values || { venueName: "" }).venueName,
    venueCompanyName: formValues(state, "venueCompanyName") || "",
    venueAddress1: formValues(state, "venueAddress1") || "",
    venueCity: formValues(state, "venueCity") || "",
    venueState: formValues(state, "venueState") || "",
    venueCountry: formValues(state, "venueCountry") || "",
    venueZip: formValues(state, "venueZip") || "",
    venueGooglePlaceId:
      formValues(state, "venueGooglePlaceId") ||
      (state.form.policy.values &&
        state.form.policy.values.venueGooglePlaceId) ||
      null,
    venueGll: formValues(state, "venueGll") || GLL_1,
    venueGlLimits: currentCoverage.venueGlLimits,
    hasInput: !!formValues(state, "venue"),
    isKentuckyEntity: kentuckyEntity,
    additionalInsured: formValues(state, "additionalInsured") || "",
    eventName: formValues(state, "eventName") || "",
    facilityCode:
      formValues(state, "facilityCode") || currentCoverage.facilityCode || "",
    venueCode:
      formValues(state, "venueCode") || currentCoverage.venueCode || "",
    manualVenueCompanyName:
      formValues(state, "manualVenueCompanyName") ||
      currentCoverage.manualVenueCompanyName,
    manualVenueAddress:
      formValues(state, "manualVenueAddress") ||
      currentCoverage.manualVenueAddress,
    manualVenueCity:
      formValues(state, "manualVenueCity") || currentCoverage.manualVenueCity,
    manualVenueState:
      formValues(state, "manualVenueState") || currentCoverage.manualVenueState,
    manualVenueCountry:
      formValues(state, "manualVenueCountry") ||
      currentCoverage.manualVenueCountry,
    manualVenueZip:
      formValues(state, "manualVenueZip") || currentCoverage.manualVenueZip,
    manualVenuePlaceId: formValues(state, "manualVenuePlaceId") || null,
    isValidManualAddress: state.form.policy.values
      ? state.form.policy.values.isValidManualAddress
      : true,
    isValidGll: state.form.policy.values
      ? state.form.policy.values.isValidGll
      : true,
    isValidDrp: state.form.policy.values
      ? state.form.policy.values.isValidDrp
      : true,
    isPristine: state.form.policy.values
      ? state.form.policy.values.isPristine
      : false,
    federalEntity: formValues(state, "federalEntity") || "",
    kentuckyStateEntity: formValues(state, "kentuckyStateEntity") || "",
    tempMunicipalityCode: formValues(state, "tempMunicipalityCode") || "",
    venueMunicipalityCode: formValues(state, "venueMunicipalityCode") || "",
    isBlocked: state.form.policy.values
      ? state.form.policy.values.isBlocked
      : currentCoverage.isBlocked,
    isInSameMunicipality: policy
      ? policy.values?.venueMunicipalityCode ===
        policy.initial?.venueMunicipalityCode
      : true,
    // Additional Insured
    facilityAddress: addressBlock(facilityAddress),
    hideFacilityInfo:
      formValues(state, "hideFacilityInfo") ||
      currentCoverage.hideFacilityInfo ||
      false,
    venueAddress: addressBlock(venueAddress),
    venueAddressAdditionalInsured: addressBlock(venueAddressAdditionalInsured),
    facilityAddressFormValues: facilityAddress,
    venueAddressFormValues: venueAddress,
    venueAddressAdditionalInsuredFormValues: venueAddressAdditionalInsured,
    waiverOfTransferRightsIncluded:
      formValues(state, "waiverOfTransferRights") !== undefined
        ? formValues(state, "waiverOfTransferRights")
        : currentCoverage.additionalCoverageWaiverOfTransferRights,
    waiverOfTransferRightsAlwaysIncluded:
      formValues(state, "waiverOfTransferRightsAlwaysIncluded") !== undefined
        ? formValues(state, "waiverOfTransferRightsAlwaysIncluded")
        : currentCoverage.waiverOfTransferRightsAlwaysIncluded,
    isValidAdditionalInsuredVenue: !!(
      venueAddressFormValues &&
      venueAddressFormValues.address1 &&
      venueAddressFormValues.city &&
      venueAddressFormValues.state &&
      venueAddressFormValues.zip
    ),
    policyDocumentFilename: currentCoverage.policyDocumentFilename,
    producer,
    optionalVenues:
      formValues(state, "optionalVenues") !== undefined
        ? formValues(state, "optionalVenues")
        : currentCoverage.optionalVenues,
    eventTypeName:
      formValues(state, "eventTypeName") || currentCoverage.eventTypeName,
    canViewReports: canViewReports(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  // Takes existing values and populates the form
  editReset: (coverage) => {
    const facilityAddress = getAdditionalInsuredInfo(coverage, TYPE_FACILITY);
    const venueAddress = getAdditionalInsuredInfo(coverage, TYPE_VENUE_ADDRESS);
    const venueAddressAdditionalInsured = getAdditionalInsuredInfo(
      coverage,
      TYPE_VENUE
    );
    const additionalInsuredText = getAdditionalInsuredInfo(coverage, TYPE_TEXT);
    dispatch(reset(policyFormName));

    const { endDate, startDate } = coverage.eventDateRange;

    let { eventSetupDates, eventTeardownDates } = getSetupAndTeardownDates(
      {
        endDate,
        startDate,
      },
      true
    );

    eventSetupDates = eventSetupDates.map((date) => ({
      ...date,
      selected: coverage.eventSetupDates.some(
        (setupDate) => setupDate === date.key
      ),
    }));

    eventTeardownDates = eventTeardownDates.map((date) => ({
      ...date,
      selected: coverage.eventTeardownDates.some(
        (teardownDate) => teardownDate === date.key
      ),
    }));

    dispatch(
      initialize(policyFormName, {
        confirmUpdate: false,
        eventName: coverage.eventName,
        eventFrequencyField: coverage.eventFrequencyField,
        eventDates: coverage.eventDates,
        eventSetupDates,
        eventTeardownDates,
        eventDateRange: coverage.eventDateRange,
        daysOfWeekField: coverage.daysOfWeekField,
        eventGuests: coverage.eventGuests,
        includeRehearsalDinner: coverage.includeRehearsalDinner,
        includeBreakfastAfterWedding: coverage.includeBreakfastAfterWedding,
        renterAddressType: coverage.renterAddressType,
        renterFirstName: coverage.renterFirstName,
        renterLastName: coverage.renterLastName,
        renterCompanyName: coverage.renterCompanyName,
        renterAddress1: coverage.renterAddress1,
        renterAddress2: coverage.renterAddress2,
        renterCity: coverage.renterCity,
        renterState: coverage.renterState,
        renterZip: coverage.renterZip,
        renterCountry: coverage.renterCountry,
        insuredCompanyType: coverage.insuredCompanyType,
        insuranceContactPhone: coverage.insuranceContactPhone,
        insuranceContactEmail: coverage.insuranceContactEmail,
        insuranceContactType: coverage.insuranceContactType,
        insuranceContactFirstName: coverage.insuranceContactFirstName,
        insuranceContactLastName: coverage.insuranceContactLastName,
        insuranceContactCompanyName: coverage.insuranceContactCompanyName,
        insuranceContactAddress1: coverage.insuranceContactAddress1,
        insuranceContactAddress2: coverage.insuranceContactAddress2,
        insuranceContactCity: coverage.insuranceContactCity,
        insuranceContactState: coverage.insuranceContactState,
        insuranceContactZip: coverage.insuranceContactZip,
        insuranceContactCountry: coverage.insuranceContactCountry,
        isFederalEntity: coverage.isFederalEntity,
        isKentuckyEntity: coverage.isKentuckyEntity,
        contactSame: coverage.contactSame,
        venue: null,
        venueSearchTypeRadio: coverage.venueSearchTypeRadio,
        venueName:
          coverage.venueSearchTypeRadio !== BY_MANUAL_ADDRESS
            ? coverage.venueCompanyName
            : "",
        venueCompanyName: coverage.venueCompanyName,
        venueGooglePlaceId: coverage.venueGooglePlaceId,
        venueAddress1: coverage.venueAddress1,
        venueCity: coverage.venueCity,
        venueState: coverage.venueState,
        venueCountry: coverage.venueCountry,
        venueZip: coverage.venueZip,
        venueUtcOffset: coverage.venueUtcOffset,
        venueMunicipalityCode: coverage.venueMunicipalityCode,
        venueGll: coverage.venueGll,
        venueDrp: coverage.venueDrp,
        venueGlLimits: coverage.venueGlLimits,
        facilityCode: coverage.facilityCode,
        venueCode: coverage.venueCode,
        manualVenueCompanyName: coverage.manualVenueCompanyName,
        manualVenuePlaceId: coverage.manualVenuePlaceId,
        manualVenueAddress: coverage.manualVenueAddress,
        manualVenueCity: coverage.manualVenueCity,
        manualVenueState: coverage.manualVenueState,
        manualVenueZip: coverage.manualVenueZip,
        manualVenueCountry: coverage.manualVenueCountry,
        facilityAddress: addressBlock(facilityAddress),
        venueAddress: addressBlock(venueAddress),
        venueAddressAdditionalInsured: addressBlock(
          venueAddressAdditionalInsured
        ),
        additionalInsured: (additionalInsuredText || { text: "" }).text,
        isValidManualAddress: coverage.isValidManualAddress,
        isValidGll: coverage.isValidGll,
        isValidDrp: coverage.isValidDrp,
        isPristine: false,
        federalEntity: "no",
        kentuckyStateEntity: "no",
        tempMunicipalityCode: coverage.venueMunicipalityCode,
        isBlocked: coverage.isBlocked,
        hideFacilityInfo: false,
        waiverOfTransferRights:
          coverage.additionalCoverageWaiverOfTransferRights,
        waiverOfTransferRightsAlwaysIncluded:
          coverage.waiverOfTransferRightsAlwaysIncluded,
        optionalVenues: coverage.optionalVenues.length
          ? coverage.optionalVenues
          : [initialOptionalVenueFormValues()],
        isInSameMunicipality: true,
        producer: coverage.producer,
        eventTypeName: coverage.eventTypeName,
        descriptionOfOperations: coverage.descriptionOfOperations,
      })
    );
  },
  // Specifically reset the Additional Insured Venue form (dedicated Discard button for this); does not affecting other forms
  resetFacilityAddressForm: (coverage) => {
    const facilityAddress = getAdditionalInsuredInfo(coverage, TYPE_FACILITY);
    dispatch(
      setFormValues(policyFormName, {
        facilityAddress: addressBlock(facilityAddress),
      })
    );
    dispatch(
      clearFieldErrors(policyFormName, [
        `${FACILITY_ADDRESS_GROUP_NAME}.companyName`,
      ])
    );
  },
  resetVenueAddressForm: (coverage) => {
    const venueAddress = getAdditionalInsuredInfo(coverage, TYPE_VENUE_ADDRESS);
    dispatch(
      setFormValues(policyFormName, {
        venueAddress: addressBlock(venueAddress),
      })
    );
  },
  resetVenueAddressAdditionalInsuredForm: (coverage) => {
    const venueAddressAdditionalInsured = getAdditionalInsuredInfo(
      coverage,
      TYPE_VENUE
    );
    dispatch(
      setFormValues(policyFormName, {
        venueAddressAdditionalInsured: addressBlock(
          venueAddressAdditionalInsured
        ),
      })
    );
  },
  resetOtherAdditionalInsuredForm: (coverage) => {
    const otherAdditionalInsured = getAdditionalInsuredInfo(
      coverage,
      TYPE_TEXT
    );
    if (otherAdditionalInsured) {
      dispatch(
        setFormValues(policyFormName, {
          additionalInsured: otherAdditionalInsured.text,
        })
      );
    }
  },
  resetProducerForm: (coverage) => {
    const producer = coverage.producer;
    dispatch(
      setFormValues(policyFormName, {
        producer,
      })
    );
  },
  isValidAddress: (
    venueAddress1,
    venueCity,
    venueState,
    venueCountry,
    venueZip
  ) => !!(venueAddress1 && venueCity && venueState && venueCountry && venueZip),
  isValidOptionalVenueAddress: (notValidOptionalVenues) =>
    notValidOptionalVenues.length === 0,
  isUniqueVenueAddresses: (notValidOptionalVenues) =>
    notValidOptionalVenues.length === 0,
  isValidState: (venueState, usStates) => {
    let isValidState = false;
    usStates.some((e) => {
      if (e.code === venueState && !e.blocked) {
        isValidState = true;
      }
      return e.code === venueState;
    });
    return isValidState;
  },
  venueInputSelect: (event) =>
    dispatch(updateVenueInputSelect(event.target.value, policyFormName)),
  searchVenueCode: (
    facilityCode,
    venueCode,
    setVenueCodeChecked,
    setValidVenueCode,
    coverage,
    setHasRegionalTax,
    setTaxRegions,
    setIsValidCity,
    setHasLookedUpTax
  ) => {
    if (
      facilityCode.length > 2 &&
      venueCode.length >= 3 &&
      venueCode.length <= 5
    ) {
      dispatch(
        searchByVenueCode(
          facilityCode,
          venueCode,
          setVenueCodeChecked,
          setValidVenueCode,
          shouldLookupTax,
          setHasRegionalTax,
          setTaxRegions,
          setIsValidCity,
          setHasLookedUpTax
        )
      );
    } else {
      dispatch(setIsFormPristine(true));
      dispatch(setIsPristineAction(true));
      setVenueCodeChecked(false);
      setValidVenueCode(false);
      dispatch(resetVenueFields(coverage));
    }
  },
  // When a venue is selected, we get place information, but have to populate the form ourselves
  onSelectVenue: (
    placeId,
    address,
    addressComponents,
    utcOffset,
    setHasSelectedVenue,
    setHasRegionalTax,
    setTaxRegions,
    setIsValidCity,
    setHasLookedUpTax,
    setVenueSearchReadOnly
  ) => {
    setHasSelectedVenue(true);
    setHasRegionalTax(false);
    setTaxRegions([]);
    setVenueSearchReadOnly(true);
    dispatch(selectPlace(placeId, address, addressComponents, utcOffset));
    dispatch(setIsFormPristine(false));
    if (
      addressComponents.address1 &&
      addressComponents.city &&
      addressComponents.state &&
      addressComponents.country &&
      addressComponents.zip
    ) {
      dispatch(
        shouldLookupTax(
          addressComponents.state,
          addressComponents.city,
          policyFormName,
          setHasRegionalTax,
          setTaxRegions,
          setIsValidCity,
          setHasLookedUpTax
        )
      );
      dispatch(checkAndSetVenue(placeId));
    }
  },
  clearForm: (
    setHasSelectedVenue,
    setHasRegionalTax,
    setTaxRegions,
    setVenueCodeChecked,
    setValidVenueCode,
    setHasLookedUpTax,
    setVenueSearchReadOnly,
    resetFacilityAddressForm,
    resetVenueAddressForm,
    resetVenueAddressAdditionalInsuredForm,
    resetOtherAdditionalInsuredForm,
    resetProducerForm,
    coverage
  ) => {
    dispatch(clearVenueSearchForm(policyFormName, coverage));
    dispatch(clearSpecialTaxForms(policyFormName));
    setHasSelectedVenue(false);
    setHasRegionalTax(false);
    setTaxRegions([]);
    setVenueCodeChecked(false);
    setValidVenueCode(false);
    setHasLookedUpTax(true);
    setVenueSearchReadOnly(false);
    dispatch(setIsFormPristine(false));
    dispatch(setIsPristineAction(false));
    resetFacilityAddressForm();
    resetVenueAddressForm();
    resetVenueAddressAdditionalInsuredForm();
    resetOtherAdditionalInsuredForm();
    resetProducerForm();
  },
  clearTaxForm: () => {
    dispatch(clearSpecialTaxForms(policyFormName));
  },
  update: () =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const state = getState();
      const coverage = state.policies.currentCoverage;
      const contactSame = values.contactSame === "yes";

      const insuranceContactType =
        values.insuranceContactType === "Person"
          ? values.insuranceContactType
          : "Other";

      const facilityAddress = {
        ...getAdditionalInsuredInfo(coverage, TYPE_FACILITY),
        type: TYPE_FACILITY,
      };
      const venueAddress = {
        ...getAdditionalInsuredInfo(coverage, TYPE_VENUE_ADDRESS),
        type: TYPE_VENUE_ADDRESS,
      };
      const venueAddressAdditionalInsured = {
        ...getAdditionalInsuredInfo(coverage, TYPE_VENUE),
        type: TYPE_VENUE,
      };
      const additionalInsuredText = {
        ...getAdditionalInsuredInfo(coverage, TYPE_TEXT),
        type: TYPE_TEXT,
      };

      const additionalInsureds = [];
      const additionalInsuredAddresses = {
        facilityAddress,
        venueAddress,
        venueAddressAdditionalInsured,
      };

      compileAdditionalInsureds(
        values,
        additionalInsuredAddresses,
        additionalInsureds
      );

      const { id } = coverage;
      const kentuckyEntity = values.kentuckyStateEntity
        ? values.kentuckyStateEntity === "yes"
        : coverage.isKentuckyEntity;
      const federalEntity = values.federalEntity
        ? values.federalEntity === "yes"
        : coverage.isFederalEntity;
      const venueSearchType = values.venueSearchTypeRadio;
      const isManualAddress = venueSearchType === BY_MANUAL_ADDRESS;
      let contactType = "";

      if (values.renterAddressType === "Person") {
        contactType = values.renterAddressType;
      } else {
        contactType = values.insuredCompanyType;
      }

      let { insuranceContactCompanyName } = values;
      if (contactSame && contactType !== "Person") {
        insuranceContactCompanyName = values.renterCompanyName;
      } else if (
        (!contactSame && insuranceContactType === "Person") ||
        (contactSame && contactType === "Person")
      ) {
        insuranceContactCompanyName = null;
      }

      const teardownDates = values.eventTeardownDates
        .filter(({ selected }) => selected)
        .map(({ key: date }) => dateHelpers.dateOnlyStringFormat(date));

      const setupDates = values.eventSetupDates
        .filter(({ selected }) => selected)
        .map(({ key: date }) => dateHelpers.dateOnlyStringFormat(date));

      const optionalVenueAddresses = getOptionalVenuesForUpdate(state);
      const data = {
        id,
        coverageApplication: {
          isKentuckyEntity: kentuckyEntity ? 1 : 0,
          isFederalEntity: federalEntity ? 1 : 0,
          venueSearchType,
          event: {
            name: values.eventName,
            dates: values.eventDates.map((date) =>
              dateHelpers.dateOnlyStringFormat(date)
            ),
            setupDates,
            teardownDates,
            eventFrequency: values.eventFrequencyField,
            averageDailyAttendance: values.eventGuests,
            includeRehearsalDinner: values.includeRehearsalDinner,
            includeBreakfastAfterWedding: values.includeBreakfastAfterWedding,
            waiverOfTransferRights: values.waiverOfTransferRights,
            eventTypeName: values.eventTypeName,
          },
          renter_address: {
            contactType,
            contactFirstName:
              contactType === "Person" ? values.renterFirstName : "",
            contactLastName:
              contactType === "Person" ? values.renterLastName : "",
            companyName:
              contactType !== "Person" ? values.renterCompanyName : "",
            address1: values.renterAddress1,
            address2: values.renterAddress2,
            city: values.renterCity,
            state: values.renterState,
            country: values.renterCountry,
            zip: values.renterZip,
          },
          insuranceContactPhone: values.insuranceContactPhone,
          insuranceContactEmail: values.insuranceContactEmail,
          insurance_contact_address: {
            contactFirstName: values.insuranceContactFirstName,
            contactLastName: values.insuranceContactLastName,
            address1: contactSame
              ? values.renterAddress1
              : values.insuranceContactAddress1,
            address2: contactSame
              ? values.renterAddress2
              : values.insuranceContactAddress2,
            city: contactSame ? values.renterCity : values.insuranceContactCity,
            state: contactSame
              ? values.renterState
              : values.insuranceContactState,
            country: contactSame
              ? values.renterCountry
              : values.insuranceContactCountry,
            zip: contactSame ? values.renterZip : values.insuranceContactZip,
            contactType: contactSame ? contactType : insuranceContactType,
            companyName: insuranceContactCompanyName,
          },
          venue_address: [
            {
              id: coverage.venueAddressId,
              placeId: isManualAddress
                ? values.manualVenuePlaceId
                : values.venueGooglePlaceId,
              companyName: isManualAddress
                ? values.manualVenueCompanyName
                : values.venueCompanyName,
              address1: isManualAddress
                ? values.manualVenueAddress
                : values.venueAddress1,
              city: isManualAddress ? values.manualVenueCity : values.venueCity,
              state: isManualAddress
                ? values.manualVenueState
                : values.venueState,
              country: isManualAddress
                ? values.manualVenueCountry
                : values.venueCountry,
              zip: isManualAddress ? values.manualVenueZip : values.venueZip,
              utcOffset: values.venueUtcOffset || 0,
              municipalTaxCode: isManualAddress
                ? ""
                : values.venueMunicipalityCode,
              facilityCode:
                values.venueSearchTypeRadio === BY_VENUE_CODE
                  ? values.facilityCode
                  : "",
              venueCode:
                values.venueSearchTypeRadio === BY_VENUE_CODE
                  ? values.venueCode
                  : "",
              venueSearchType,
            },
            ...optionalVenueAddresses,
          ],
          referralProducer: values.referralProducer,
          description_of_operations: values.descriptionOfOperations,
        },
        additional_insureds: additionalInsureds,
      };
      if (additionalInsuredText) {
        data.additional_insureds.push({
          id: additionalInsuredText.id,
          type: TYPE_TEXT,
          text: values.additionalInsured || "",
        });
      } else if (values.additionalInsured) {
        data.additional_insureds.push({
          type: TYPE_TEXT,
          text: values.additionalInsured,
        });
      }
      return new CoverageService(new API())
        .putCoverageEditDetails(data)
        .then(() => {
          dispatch(getPolicy(id));
        });
    }),
  cancel: () =>
    dispatch((_, getState) => {
      const state = getState();
      const coverage = state.policies.currentCoverage;
      const { id } = coverage;
      return new CoverageService(new API())
        .postCancelCoverage(id)
        .then((response) => {
          dispatch(getPolicy(id));
          return response.data;
        });
    }),
  modalToggle: (modal, open) => dispatch(toggleModal(modal, open)),
  deleteDate: (date) => dispatch(deleteDateAction(date)),
  lookupTax: (
    state,
    city,
    setHasRegionalTax,
    setTaxRegions,
    setIsValidCity,
    setHasLookedUpTax
  ) => {
    setHasRegionalTax(false);
    if (state && city) {
      debounce.do(() => {
        dispatch(clearVenueMunicipalityCode());
        dispatch(clearSpecialTaxForms(policyFormName));
        dispatch(
          shouldLookupTax(
            state,
            city,
            policyFormName,
            setHasRegionalTax,
            setTaxRegions,
            setIsValidCity,
            setHasLookedUpTax
          )
        );
        dispatch(setIsFormPristine(false));
      });
    }
  },
  lookupUtc: (zip) => {
    if (zip.length === 5 || zip.length === 10) {
      debounce.do(() => dispatch(getCoordsForUtc(zip)));
    } else {
      debounce.do(() => dispatch(getCoordsForUtc()));
    }
  },
  setKentuckyCity: (city) => {
    dispatch(setKentuckyCityAction(policyFormName, city));
  },
  setCanUpdatePolicy: (val) => dispatch(confirmUpdatePolicy(val)),
  setIsPristine: (val) => dispatch(change(policyFormName, "isPristine", val)),
  clearAddressGroup: (formGroup) =>
    dispatch(
      batchUpdateFormFields(policyFormName, [
        `${formGroup}.companyName`,
        `${formGroup}.address1`,
        `${formGroup}.address2`,
        `${formGroup}.city`,
        `${formGroup}.state`,
        `${formGroup}.zip`,
      ])
    ),
  goToProducer: (id) =>
    dispatch(ConnectedRouter.push(getUrl(PRODUCER_PAGE, id))),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  editReset: () => dispatchProps.editReset(stateProps.coverage),
  resetFacilityAddressForm: () =>
    dispatchProps.resetFacilityAddressForm(stateProps.coverage),
  resetVenueAddressForm: () =>
    dispatchProps.resetVenueAddressForm(stateProps.coverage),
  resetVenueAddressAdditionalInsuredForm: () =>
    dispatchProps.resetVenueAddressAdditionalInsuredForm(stateProps.coverage),
  resetOtherAdditionalInsuredForm: () =>
    dispatchProps.resetOtherAdditionalInsuredForm(stateProps.coverage),
  resetProducerForm: () => {
    dispatchProps.resetProducerForm(stateProps.coverage);
  },
  isValidAddress: () => {
    const isManualAddress =
      stateProps.venueSearchTypeRadio === BY_MANUAL_ADDRESS;
    return dispatchProps.isValidAddress(
      isManualAddress
        ? stateProps.manualVenueAddress
        : stateProps.venueAddress1,
      isManualAddress ? stateProps.manualVenueCity : stateProps.venueCity,
      isManualAddress ? stateProps.manualVenueState : stateProps.venueState,
      isManualAddress ? stateProps.manualVenueCountry : stateProps.venueCountry,
      isManualAddress ? stateProps.manualVenueZip : stateProps.venueZip
    );
  },
  isValidOptionalVenueAddress: () => {
    const optionalVenues = stateProps.optionalVenues;

    const notValidOptionalVenues = optionalVenues.filter((optionalVenue) => {
      const { selectedPlaceAddressComponents } = optionalVenue;

      if (!isEmpty(selectedPlaceAddressComponents)) {
        return !(
          selectedPlaceAddressComponents.address1 &&
          selectedPlaceAddressComponents.city &&
          selectedPlaceAddressComponents.state &&
          selectedPlaceAddressComponents.country &&
          selectedPlaceAddressComponents.zip
        );
      }

      return false;
    });

    return dispatchProps.isValidOptionalVenueAddress(notValidOptionalVenues);
  },
  isUniqueVenueAddresses: () => {
    const { optionalVenues, venueGooglePlaceId } = stateProps;
    const notValidOptionalVenues = optionalVenues.filter((optionalVenue) => {
      if (!venueGooglePlaceId || !optionalVenue.selectedPlaceId) return false;
      return venueGooglePlaceId === optionalVenue.selectedPlaceId;
    });
    return dispatchProps.isValidOptionalVenueAddress(notValidOptionalVenues);
  },
  isValidState: () => {
    const isManualAddress =
      stateProps.venueSearchTypeRadio === BY_MANUAL_ADDRESS;
    return dispatchProps.isValidState(
      isManualAddress ? stateProps.manualVenueState : stateProps.venueState,
      stateProps.usStates
    );
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: policyFormName,
  })
)(Policy);
