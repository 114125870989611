import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import StatusField from "./FormElements/StatusField";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { facilityPropType } from "../../Helpers/FacilityModel";
import ReferralLinkToast from "../../Components/ReferralLinkToast";

const FacilityStatus = ({ editing, facility }) => {
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <div className="card border-0">
        <Form.Row className="justify-content-between align-items-center mb-4">
          <Col xs="auto">
            {editing ? (
              <Field
                component={StatusField}
                name="facilityStatus"
                type="select"
              />
            ) : (
              <>
                <span className="label mr-2">Status:</span>
                <span>{stringHelpers.firstToUpper(facility.status)}</span>
              </>
            )}
          </Col>
        </Form.Row>
      </div>
      <ReferralLinkToast
        show={showToast}
        setShowToast={setShowToast}
        text="Organization"
      />
    </>
  );
};

FacilityStatus.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
};

export default FacilityStatus;
