import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import { MANUAL_NOTE_TYPE, producerContactsPropType } from "../constants";
import notePropType from "./NoteModel";

/**
 *
 * Model used to represent a `Facility`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyFacility = {
  id: 0,
  code: "",
  hideFacilityInfo: false,
  producerId: 0,
  producerName: "",
  producerCommissionId: "",
  producer: {
    producerContacts: [],
  },
  facilityProducerContacts: [],
  venuesCount: 0,
  states: [],
  created: "",
  updated: "",
  status: "",
  facilityContacts: [],
  otherAdditionalInsured: {
    id: 0,
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  },
  facilityNotes: [],
  referralLink: "",
  logoUrl: null,
  descriptionOfOperations: "",
};

/**
 *
 * PROP TYPES
 *
 */
const additionalInsuredPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
});
const facilityContactsPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  copyOnEmails: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
});
const facilityNotesPropType = notePropType;
const facilityPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  hideFacilityInfo: PropTypes.bool.isRequired,
  producerId: PropTypes.number.isRequired,
  producerName: PropTypes.string.isRequired,
  producerCommissionId: PropTypes.string.isRequired,
  emailProducerContacts: PropTypes.arrayOf(producerContactsPropType),
  venuesCount: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  facilityContacts: PropTypes.arrayOf(facilityContactsPropType),
  otherAdditionalInsured: additionalInsuredPropType,
  facilityNotes: PropTypes.arrayOf(facilityNotesPropType),
  logoUrl: PropTypes.string,
  descriptionOfOperations: PropTypes.string,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerFacilityToFacilityModel = (facility) => {
  const model = cloneDeep(emptyFacility);
  model.id = facility.id;
  model.code = facility.code;
  model.hideFacilityInfo = facility.hideFacilityInfo;
  model.producerId = facility.producer.id;
  model.producerName = facility.producer.name;
  model.producerCommissionId = facility.producer.commissionId;
  model.subProducerCode = facility.producer.sub_producer_code;
  model.venuesCount = facility.venues_count;
  model.states = facility.states;
  model.created = facility.created_at;
  model.updated = facility.updated_at;
  model.status = facility.status;
  model.logoUrl = facility.logo_url;
  model.descriptionOfOperations = facility.description_of_operations;

  model.facilityProducerContacts = facility.facility_producer_contacts.map(
    (m) => ({
      producerContactId: m.id,
      fullName: m.fullName || "",
      email: m.email,
    })
  );

  let producerContacts = [];
  if ("producer_contacts" in facility.producer) {
    producerContacts = facility.producer.producer_contacts.map((m) => ({
      producerContactId: m.id,
      fullName: m.fullName || "",
      email: m.email,
    }));
  }

  if ("facility_producer_contacts" in facility) {
    producerContacts.filter((c) => {
      const contact = c;
      contact.copyOnEmails = facility.facility_producer_contacts.some(
        (pc) => c.producerContactId === pc.id
      );
      model.producer.producerContacts.push(contact);
      return true;
    });
  }

  const otherInsured = facility.additional_insured_address;
  if (otherInsured) {
    model.otherAdditionalInsured = {
      id: otherInsured.id,
      companyName: otherInsured.companyName,
      address1: otherInsured.address1,
      address2: otherInsured.address2,
      city: otherInsured.city,
      state: otherInsured.state,
      zip: otherInsured.zip,
      country: otherInsured.country,
    };
  }

  model.facilityContacts = facility.facility_contacts.map((m) => ({
    id: m.id,
    fullName: m.fullName || "",
    role: m.role || "",
    copyOnEmails: m.copyOnEmails,
    email: m.email,
  }));

  model.facilityNotes = facility.facility_notes.map((m) => ({
    id: m.id,
    type: m.type,
    message: m.message,
    userName: m.type === MANUAL_NOTE_TYPE ? m.user?.name || "" : "",
    created: m.created_at,
  }));

  model.referralLink = (
    facility.links || { facilityReferralLink: "" }
  ).facilityReferralLink;
  return model;
};

const editFacilityInitialFormValues = (facility, otherInsured) => ({
  facilityCode: facility.code,
  facilityStatus: facility.status,
  hideFacilityInfo: facility.hideFacilityInfo,
  producer: {
    value: facility.producerId,
    label: `${facility.producerName} — ${facility.producerCommissionId}${
      facility.subProducerCode ? ` - ${facility.subProducerCode}` : ""
    }`,
    producerContacts: facility.producer.producerContacts,
  },
  otherInsured: {
    companyName: otherInsured.companyName,
    address1: otherInsured.address1,
    address2: otherInsured.address2,
    city: otherInsured.city,
    state: otherInsured.state,
    zip: otherInsured.zip,
    country: otherInsured.country,
  },
  contacts: facility.facilityContacts,
  facilityLogoUrl: facility.logoUrl || null,
  facilityImgFile: null,
  descriptionOfOperations: facility.descriptionOfOperations,
});

const initialFacilityFormValues = () => cloneDeep(emptyFacility);

export {
  emptyFacility,
  facilityPropType,
  additionalInsuredPropType,
  convertServerFacilityToFacilityModel,
  editFacilityInitialFormValues,
  initialFacilityFormValues,
};
