import { isString, isObject } from "lodash";
import { FACILITY_CONTACT_TYPE, VENUE_CONTACT_TYPE } from "../constants";
import { duplicateIndices } from "./ArrayHelpers";
import {
  FACILITY_CODE_REGEX,
  minFacilityCodeLength,
  maxFacilityCodeLength,
} from "@gbli-events/common/src/Constants/codes";

const isNaN = document.isNan;

// Quick list of simple validators grabbed from Redux Form's site.
// Seriously needs tests and filling out with further functions
export const eligibility = (value) =>
  value !== "no" ? `This must be no` : undefined;
export const notZero = (value) =>
  value === "0" || value === 0 ? "0" : undefined;
export const withoutInsurance = (value) => (value === "no" ? undefined : "yes");
export const required = (value) =>
  value || typeof value === "number" ? undefined : "Required";
export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;
export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const requiredDates = (value) => {
  const dates = Object.values(value || {});
  for (let i = 0; i < dates.length; i += 1) {
    if (!dates[i]) {
      return "Required";
    }
  }
  return undefined;
};
export const arrayNotEmpty = (value) => (value.length ? undefined : "Required");

// regex below from http://emailregex.com/
export const email = (value) =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@"]{1,64}(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"){1,64})@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? "Invalid email address"
    : undefined;
export const alphaNumeric = (value) =>
  value && !/[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;
export const phoneNumber = (value) =>
  // (XXX) XXX-XXXX
  value && !/^((\([0-9]{3}\)) [0-9]{3}-[0-9]{4})$/.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;
export const zipCode = (value) =>
  // 90210 or 90210-0120
  !value || (value && /^\d{5}(?:-\d{4})?$/i.test(value))
    ? undefined
    : "Invalid. Valid examples: 90210 or 53203-2104";
export const facilityOtherAdditionalInsuredZipCode = (value) => {
  // 90210 or 90210-0120 or empty
  if ((value && /^\d{5}(?:-\d{4})?$/i.test(value)) || !value) {
    return undefined;
  }
  return "Invalid. Valid examples: 90210 or 53203-2104";
};
export const descriptionOfOperationsMaxLength = 300;
export const descriptionOfOperationsMaxLengthValidator = maxLength(
  descriptionOfOperationsMaxLength
);
export const sameContact = (value) =>
  value === "yes" ? undefined : "not the same contact as insured";
export const renterContact = (value) =>
  value === "individual" ? undefined : "company";
export const samePayee = (value) =>
  value === "yes" ? undefined : "not the same billing address as contact";
export const renterPayee = (value) =>
  value === "individual" ? undefined : "company";
export const dateInput = (value) =>
  value && /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/g.test(value)
    ? undefined
    : "Invalid date, must be MM/DD/YYYY";
export const atLeastOneDayMustBePicked = (_, allValues) =>
  allValues.daysOfWeekField.reduce((prev, curr) => (curr ? true : prev), false)
    ? undefined
    : "At least one day must be picked";
export const producerCommissionId = (value) =>
  value && /^([a-zA-Z0-9]{1,6})$/i.test(value)
    ? undefined
    : "Invalid, must be 6 numbers/letters";

export const associationCode = (value) =>
  value && /^([a-zA-Z0-9]{1,4})$/i.test(value)
    ? undefined
    : "Invalid, must be 1-4 numbers/letters";
export const masterAgencyCode = (value) =>
  !value || (value && /^([a-zA-Z0-9]{1,5})$/i.test(value))
    ? undefined
    : "Invalid, must be 1-5 numbers/letters";

// The max placeId length is somewhat arbitrary; Google Placd IDs have been known
// to exceed 300 characters (the more specific the address, the longer the ID)
export const placeId = (value) =>
  (isString(value) && value.length >= 27 && value.length <= 768) ||
  (isObject(value) && value.value.length >= 27 && value.value.length <= 768)
    ? undefined
    : "Invalid place id";

export const atLeastOneCheckbox =
  (controlName, errorMsg = "At least one must be picked") =>
  (_, allValues) =>
    allValues[controlName] &&
    allValues[controlName].reduce((prev, curr) => (curr ? true : prev), false)
      ? undefined
      : errorMsg;
export const placeMustBeUniqueLocal = (value, allValues) =>
  allValues.places
    .reduce((arr, curr) => {
      if (curr.placeId) {
        arr.push(curr.placeId.value);
      }
      return arr;
    }, [])
    .filter((current, index, array) => array.indexOf(current) !== index)
    .includes(value.value)
    ? { ...value.venue, message: "Place ID is already located in " }
    : undefined;
export const placeMustBeUnique = (value) =>
  value.venue && value.checked === true
    ? { ...value.venue, message: "Place ID is already located in " }
    : undefined;

export const policyContactEmailsUniqueMessage =
  "This email already exists for this policy";
export const policyContactEmailsUnique = (value, allValues, props) => {
  // Test email does not match other Custom Contact email
  let hasCustomContactDuplicate = false;
  const { customContacts } = allValues;
  if (customContacts.length > 1) {
    hasCustomContactDuplicate = customContacts.reduce(
      (arr, curr) => arr.email === curr.email && curr.email === value
    );
  }

  // Test email does not match any other policy emails
  const {
    insuranceContactEmail,
    producerContacts,
    facilityContacts,
    venueContacts,
  } = props.coverage;

  const allContacts = [{ email: insuranceContactEmail || "" }].concat(
    producerContacts || [],
    facilityContacts || [],
    venueContacts || []
  );

  const hasOtherPolicyContactDuplicate = allContacts.some((ac) => {
    return ac.email === value;
  });

  if (hasCustomContactDuplicate || hasOtherPolicyContactDuplicate) {
    return policyContactEmailsUniqueMessage;
  }
  return undefined;
};

export const facilityCodeValidation = (value) =>
  value && FACILITY_CODE_REGEX.test(value)
    ? undefined
    : `Invalid, must be ${minFacilityCodeLength} to ${maxFacilityCodeLength} numbers/letters`;

export const venueCodeValidation = (value) =>
  value && !/^\d{3,5}$/i.test(value) ? "Only numbers" : undefined;

export const contactEmailUpdate = (value, _allValues, props) => {
  if (value) {
    const { contactsTableData, selectedContacts, contactType } = props;
    let type = "producerId";
    if (contactType === FACILITY_CONTACT_TYPE) {
      type = "facilityId";
    } else if (contactType === VENUE_CONTACT_TYPE) {
      type = "venueId";
    }
    const selectedContactsType = contactsTableData
      .filter((_contact, i) => selectedContacts[i] === true)
      .map((contact) => contact[type]);
    return new Set(selectedContactsType).size !== selectedContactsType.length
      ? `This update would apply to multiple contacts under the same ${contactType}. Contacts for the same ${contactType} must have unique email addresses.`
      : undefined;
  }
  return undefined;
};

export const firstCharacterIsNumber = (value) =>
  /^\d$/.test(value.charAt(0)) ? undefined : "First character is not a number";

export const contactEmailsUniqueMessage =
  "This email already exists for another contact";
export const contactEmailsUnique = (values, contactFieldName = "contacts") => {
  const errors = {};
  errors[contactFieldName] = [];
  const contacts = values[contactFieldName] || [];
  const duplicates = duplicateIndices(
    contacts.map((a) => (a.email || "").toLowerCase())
  );
  contacts.forEach((v, i) => {
    errors[contactFieldName].push(
      duplicates.includes(i) ? { email: contactEmailsUniqueMessage } : {}
    );
  });
  return errors;
};
export const facilityProducer = (value) =>
  value && value.label && value.value ? undefined : "Producer must be selected";

export const dateFormat = (value) =>
  // YYYY-MM-DD
  value && !/^(([0-9]{4})-[0-9]{2}-[0-9]{2})$/.test(value)
    ? "Valid date format is YYYY-MM-DD"
    : undefined;
